import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Link } from 'gatsby';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import OutboundLink from '../../components/OutboundLink';

const AlumniNews = () => (
  <Layout title="Alumni News" className="news-and-events">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-light-blue"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="../../news-and-events/recent-news">News</Link>
          </li>
          <li className="breadcrumb active">Alumni News</li>
        </ul>
        <h1 className="page-title">Alumni News</h1>
        <Image filename="hero-news-and-events.png" className="hero-image" />
      </div>
    </div>
    {/* Recent News Intro */}
    <Section>
      <Container>
        {/* Recent News Intro */}
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h2 id="news-intro">Find out what’s new in the world of science</h2>
            <h4>
              Stay connected to all the latest STEM news, important challenge
              updates and much more. Browse regularly updated content like blog
              entries, challenge exclusives, and thought starters, along with
              insights from today’s top young scientists.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Recent News Main Content */}
    <Section>
      <Container>
        <Row>
          {/* Recent News Left Column */}
          <Column size={9}>
            <ul className="filter-tabs news-and-events">
              <li className="filter-tabs__tab selected">
                <Link to="#news-intro">2022</Link>
              </li>
              <li className="filter-tabs__tab">
                <Link to="../recent-news#news-intro">Program Updates</Link>
              </li>
              <li className="filter-tabs__tab">
                <Link to="../ysc-blog#news-intro">Young Scientist Blog</Link>
              </li>
              {/* This will be turned back on AFTER site launch */}
              {/* <li className="filter-tabs__tab selected">
                <Link to="../alumni-news#news-intro">Alumni</Link>
              </li> */}
            </ul>
          </Column>
          {/* In The News and Press Releases Right Column */}
          <Column size={3}>
            <h3>In The News</h3>
            <h4>In active development...</h4>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AlumniNews;
